<script lang="ts" setup>
// looping over sections, using dynamic components, in a typed way
import {
  LazySectionsProducts,
  SectionsAllDomains,
  SectionsAllGalleries,
  SectionsArticles,
  SectionsBanner,
  SectionsCategories,
  SectionsContactInfoCta,
  SectionsContent,
  SectionsDiscovery,
  SectionsEngraving,
  SectionsFallback,
  SectionsGallery,
  SectionsSplit,
  SectionsSwiperInfoCta,
  SectionsUsps,
} from "#components";

defineProps<{ sections: any; currentPageId: string }>();

function GetComponentName(typename: string) {
  switch (typename) {
    case "AllDomainsSectionRecord":
      return SectionsAllDomains;
    case "AllGalleriesSectionRecord":
      return SectionsAllGalleries;
    case "ArticlesSectionRecord":
      return SectionsArticles;
    case "BannerSectionRecord":
      return SectionsBanner;
    case "CategoriesSectionRecord":
      return SectionsCategories;
    case "ContactInfoCtaSectionRecord":
      return SectionsContactInfoCta;
    case "ContentSectionRecord":
      return SectionsContent;
    case "DiscoverySectionRecord":
      return SectionsDiscovery;
    case "EngravingSectionRecord":
      return SectionsEngraving;
    case "GallerySectionRecord":
      return SectionsGallery;
    case "ProductsSectionRecord":
      return LazySectionsProducts;
    case "SplitSectionRecord":
      return SectionsSplit;
    case "SwiperInfoCtaSectionRecord":
      return SectionsSwiperInfoCta;
    case "UspSectionRecord":
      return SectionsUsps;
    default:
      return SectionsFallback;
  }
}
</script>

<template>
  <div>
    <section v-for="section in sections" :key="section.id" class="mb-16 mt-4">
      <div>
        <component :is="GetComponentName(section.__typename)" :data="section" :current-page-id="currentPageId" />
      </div>
    </section>
  </div>
</template>

<style scoped>

</style>
